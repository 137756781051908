/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.mdc-dialog > .mdc-dialog__container {
  max-width: unset !important;
}
.xircles-my-account-dialog {
  align-items: unset !important;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.xircles-my-account-dialog .mdc-dialog__container {
  display: block !important;
  height: fit-content !important;
  margin: 40px 0;
}
.xircles-my-account-dialog .mdc-dialog__container .transition-group {
  max-height: 767px;
}
.xircles-my-account-dialog .mdc-dialog__container section.route-section {
  position: static !important;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content {
  padding-bottom: 20px !important;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .mdc-text-field.mdc-text-field--outlined {
  box-shadow: none;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .select-wrapper .mdc-select.mdc-select--focused.mdc-select--outlined + .mdc-menu {
  display: inline-block;
  transform-origin: center top;
  max-height: 232px;
  opacity: 1;
  z-index: 100;
  top: 58px;
  left: 0;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .select-wrapper {
  position: relative;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .select-wrapper .mdc-select {
  width: 100%;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .mdc-text-field--disabled + .mdc-icon-button {
  pointer-events: none;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .mdc-text-field--disabled + .mdc-icon-button i {
  height: 20px;
  width: 15px;
  content: url('../../../images/lock.svg');
  background-image: url('../../../images/lock.svg');
  background-size: cover;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .mdc-icon-button {
  position: absolute;
  right: 0;
  top: 5px;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper {
  max-width: 305px;
  min-width: 305px;
  position: relative;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper span {
  cursor: pointer;
  color: #E065A4;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper span.link--disabled {
  pointer-events: none;
  color: #aaacb2;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper i:focus {
  outline: none;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-with-helper-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-with-helper-wrapper .mdc-text-field-helper-line {
  position: absolute;
  left: 0;
  bottom: -20px;
  padding: 0 !important;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-with-helper-wrapper .mdc-text-field-helper-line p {
  color: #b00020;
  margin: 0 !important;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper.text-field-with-helper-wrapper .mdc-text-field-helper-line {
  max-width: 225px;
  bottom: unset;
  top: 63px;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content .text-field-wrapper.text-field-with-helper-wrapper span.link--disabled {
  pointer-events: none;
  color: #aaacb2;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content #text-field-phone-number .mdc-text-field-helper-line {
  bottom: -19px;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content #text-field-phone-number .mdc-text-field-helper-line p {
  max-width: 175px;
}
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content #text-field-phone-number input,
.xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__content #text-field-email input {
  padding-right: 40px !important;
}
@media screen and (max-width: 767px) {
  .xircles-my-account-dialog .mdc-dialog__container {
    min-height: 100% !important;
  }
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface {
    min-width: unset !important;
  }
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface .transition-group {
    max-height: unset;
  }
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface section.route-section .text-field-wrapper + .text-field-wrapper,
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface section.route-section .mdc-text-field + .mdc-text-field,
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface section.route-section .text-field-with-helper-wrapper + .text-field-with-helper-wrapper,
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface .text-field-wrapper + .select-wrapper {
    margin: 27px 0 0 0 !important;
  }
  .xircles-my-account-dialog .mdc-dialog__container .mdc-dialog__surface .text-field-wrapper {
    min-width: unset !important;
  }
  .xircles-my-account-dialog .controls-wrapper {
    margin-top: 10px !important;
  }
  .xircles-my-account-dialog .controls-wrapper div {
    width: 100% !important;
  }
}
