/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.content-panel__medicine-card {
  width: 315px;
  height: 262px;
  margin-left: 20px;
}
.content-panel__medicine-card p:first-of-type {
  display: inline-block;
  margin: 30px 0 0 0 !important;
}
.medicine-card__image-container {
  display: flex;
  margin: 20px 0 0 -5px;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .medicine-card__image-container {
    justify-content: flex-start;
  }
}
.medicine-card__image {
  width: 151px;
  height: 112px;
}
@media screen and (max-width: 767px) {
  .medicine-card__image {
    width: 137px;
    height: 102px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 320px) {
  .medicine-card__image {
    width: 46%;
  }
}
.medicine-card__title {
  max-width: 100%;
  margin: 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #494d5b;
}
.medicine-card__title:nth-child(3) {
  margin-top: 2px;
}
.medicine-card__subtitle {
  margin: 2px 0 0 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaacb2;
}
.medicine-card__link {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.medicine-card__link-title {
  display: flex;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.medicine-card__link-title + p.medicine-card__title {
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .content-panel__medicine-card {
    width: 335px;
  }
}
@media screen and (max-width: 320px) {
  .content-panel__medicine-card {
    width: 100%;
    margin-left: 15px;
  }
}
