/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-faqs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 678px;
  margin: 0 auto 166px auto;
}
.product-faqs h2 {
  font-weight: bold;
  margin: 0;
}
.product-faqs .product-faqs__content {
  margin: 40px 0 0 0;
  padding: 0;
}
.product-faqs .product-faqs__content .product-faq {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  position: relative;
  list-style-type: none;
  background-color: white;
  height: 49px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
}
.product-faqs .product-faqs__content .product-faq:last-of-type {
  margin-bottom: 0;
}
.product-faqs .product-faqs__content .product-faq .product-faq__title {
  margin: 0;
  max-width: 485px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-faqs .product-faqs__content .product-faq .product-faq__title b {
  font-size: 24px;
}
.product-faqs .product-faqs__content .product-faq .product-faq__title:not(.product-faq__title--labeled) {
  margin-right: 45px;
}
.product-faqs .product-faqs__content .product-faq i.material-icons {
  position: absolute;
  right: 10px;
  color: #E065A4;
}
.product-faqs .product-faqs__content .product-faq span {
  color: white;
  background-color: #8DD2CF;
  box-sizing: border-box;
  height: 23px;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 3px;
  margin: 0 45px 0 10px;
  min-width: 58px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product-faqs h2,
.product-faqs .product-faq a {
  font-family: Roboto;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #494d5b;
}
@media only screen and (max-width: 767px) {
  .product-faqs {
    padding: 0 20px 20px;
  }
  .product-faqs .product-faq {
    height: 43px;
  }
  .product-faqs .product-faq__title,
  .product-faqs b {
    font-size: 16px !important;
  }
}
