.buttonbar {
  padding: 18px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  min-height: 36px;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid rgba(110, 116, 143, 0.2);
}

.buttonbar button {
  margin-left: 10px;
  outline: none;
  font-size: 16px;
  padding: 12px 18px;
  border: solid 1px rgb(0, 155, 119);
  border-radius: 21px;
  text-align: center;
  color: rgb(0, 155, 119);
  background: white;
}

.buttonbar button:hover {
  cursor: pointer;
}
.buttonbar button:hover,
.buttonbar button:active {
  color: rgb(0, 155, 119);
}
