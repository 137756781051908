/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.answerPage {
  opacity: 0;
  transition: all 200ms 600ms ease-in, opacity 0ms 600ms ease-in;
}
.answerPage .scroll-table-right {
  background-color: #E065A4;
}
.answerPage .layer-list-meta .layer-has-conversation {
  display: none;
}
.answerPage.fade-exit .background-menu {
  box-shadow: unset !important;
}
.answerPage.fade-exit-active .back-button {
  transform: rotate(-90deg);
  opacity: 0 !important;
  transition-delay: 0ms !important;
  transition: all 200ms ease-in-out;
}
.answerPage.fade-exit-active layer-message-list,
.answerPage.fade-exit-active layer-compose-bar,
.answerPage.fade-exit-active .panel-header * {
  opacity: 0;
  transition: opacity 200ms 0ms ease-in-out;
}
.answerPage.fade-exit-active layer-conversation-view {
  opacity: 1 !important;
  background-color: #f0f1f3 !important;
  transition: background-color 400ms 200ms ease-in-out, opacity 0ms 200ms !important;
}
.answerPage.fade-exit-active .panel-header {
  min-height: 434px !important;
  transition: min-height 400ms ease-in-out !important;
  transition-delay: 200ms !important;
}
.answerPage layer-conversation-view {
  padding-top: 154px;
  height: calc(100vh - 154px) !important;
}
@media only screen and (max-width: 767px) {
  .answerPage layer-conversation-view {
    padding-top: 48px;
    height: calc(100vh - 48px) !important;
  }
}
.answerPage layer-conversation-view.conversation-view-snapshot {
  padding-top: 200px;
  height: calc(100vh - 200px) !important;
}
.answerPage layer-link-message-view .video-description {
  margin: 0;
  color: #ffffff;
  padding: 10px 30px 0 10px;
  line-height: 19px;
  font-size: 16px;
  font-weight: normal;
}
.answerPage layer-conversation-view layer-message-item-sent:first-of-type {
  display: none;
}
.answerPage layer-conversation-view layer-message-item-received .layer-sender-name {
  display: none;
  font-weight: 500;
  font-size: 0.75rem;
}
.answerPage layer-conversation-view layer-message-item-received .bot {
  color: #8DD2CF;
}
.answerPage layer-conversation-view layer-message-item-received .show-sender {
  display: block;
}
.answerPage layer-conversation-view layer-message-item-received.disabled-message,
.answerPage layer-conversation-view layer-message-item-sent.disabled-message {
  pointer-events: none;
  opacity: 0.4;
}
.answerPage .layer-list-meta .layer-header-toggle .layer-load-indicator .layer-replaceable-inner {
  position: relative;
  width: 87px;
  height: auto;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator {
  width: 87px;
  height: 87px;
  margin: 60px 0 0 0;
  position: absolute;
  z-index: 10;
  left: 0;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator > div {
  all: unset !important;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator svg {
  display: block !important;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator .loading-spinner {
  position: absolute;
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: transparent;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator .loading-spinner .spinner {
  position: relative;
  animation: rotate 1s linear infinite !important;
  width: 67px;
  height: 67px;
  top: 0;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator .loading-spinner .spinner-dot {
  stroke: #494d5b;
  fill: #494d5b;
  animation: skew 1s linear infinite alternate;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator .loading-spinner .spinner-border {
  fill: transparent;
  stroke-width: 5px;
}
.answerPage .layer-list-meta .layer-header-toggle layer-loading-indicator .loading-spinner .path {
  stroke-dasharray: 170;
  stroke-dashoffset: 33;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes skew {
  from {
    transform: skewX(10deg);
  }
  to {
    transform: skewX(40deg);
  }
}
.answerPage .layer-compose-bar__border-top {
  border-top: 1px solid #f0f1f3;
}
.answerPage .layer-compose-bar__border-top layer-replaceable-content {
  padding-top: 1px;
}
.answerPage .show-more {
  min-height: auto;
  z-index: 10;
  opacity: 1;
}
.answerPage .show-more button {
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: calc(50% - 18px);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.answerPage .show-more.xircles-hide {
  opacity: 0;
}
.answerPage .enable-compose-bar .layer-compose-edit-panel {
  display: block;
}
.answerPage .enable-compose-bar .layer-replaceable-inner {
  display: flex !important;
}
.answerPage .show-more-button {
  width: 36px;
  height: 30px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: #f0f1f3;
}
.answerPage .show-more-button i {
  color: #494d5b;
}
.answerPage.open {
  opacity: 1;
}
.answerPage.open .back-button,
.answerPage.open .xircles-status {
  transition: all 200ms 230ms ease-in-out;
  opacity: 1;
}
.answerPage.open .panel-header button i {
  opacity: 1;
  transform: rotate(0);
}
.answerPage.open .panel-header > div:not(.login):not(.back-button) {
  max-width: 650px;
  min-width: 650px;
  margin: 0 auto;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .answerPage.open .panel-header > div:not(.login):not(.back-button) {
    min-width: unset;
    padding: 0 40px 0 2px;
    margin: 0;
  }
}
.answerPage.open .question-panel layer-conversation-view {
  opacity: 1;
  background-color: white;
}
.answerPage .question-panel {
  color: #494d5b;
  background-color: rgba(0, 0, 0, 0);
}
.answerPage .question-panel layer-message-list {
  height: 100% !important;
  overflow-x: hidden;
}
.answerPage .question-panel layer-conversation-view {
  height: 100%;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
}
.answerPage .question-panel .operator + layer-conversation-view {
  height: calc(100% - 35px) !important;
}
.answerPage .question-panel .panel-header .title {
  font-size: 24px;
  color: #494d5b;
  font-weight: bold;
  margin: 12px 28px 12px 12px;
}
.answerPage .panel-header button {
  border: none;
  padding: 0;
  line-height: 0.7;
  cursor: pointer;
}
.answerPage .panel-header button i {
  background: rgba(0, 0, 0, 0);
  color: #E065A4;
  transition: all 200ms 230ms ease-in-out;
  opacity: 0;
  transform: rotate(-90deg);
}
.answerPage .back-button {
  width: 30px;
  opacity: 0;
}
.answerPage .xircles-operator-status {
  display: none;
}
.answerPage .header-operator .operator-status__wrapper {
  position: absolute;
  top: -12px;
  right: 20px;
  display: flex;
}
.answerPage .header-operator .operator-status__wrapper div:not(:last-of-type) {
  margin-right: 23px;
}
.answerPage .header-operator .operator-status__wrapper .xircles-status {
  position: relative;
  float: unset;
  right: unset;
  top: unset;
}
.answerPage .header-operator .operator-status__wrapper .xircles-operator-status {
  display: block;
  width: fit-content;
  padding: 3px 10px;
  background: #aaacb2;
  border-radius: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  color: white;
}
.answerPage .xircles-status {
  float: right;
  position: absolute;
  right: 20px;
  top: 12px;
  opacity: 0;
  display: block;
}
.answerPage .layer-message-item-xircles-button-link-message-view .layer-message-item-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
}
@media only screen and (max-width: 767px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
    padding: 0 30px;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 600px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
    padding: 0 28px;
  }
}
@media only screen and (max-width: 460px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 767px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main .layer-carousel-message-view {
    margin: 0;
    width: 100%;
  }
}
.answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
@media only screen and (max-width: 600px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 460px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
    padding-right: 2px;
  }
}
.answerPage .layer-carousel-message-view {
  overflow: visible;
  max-width: 660px !important;
}
.answerPage .layer-carousel-message-view layer-message-viewer:nth-child(1) {
  margin-left: 0;
}
.answerPage .layer-carousel-message-view layer-message-viewer:last-child {
  margin-right: 0;
}
.answerPage .layer-carousel-message-view .layer-carousel-message-view-items:after {
  display: none;
}
.answerPage .layer-message-type-view span:nth-child(1) {
  left: -44px;
}
.answerPage .layer-next-icon,
.answerPage .layer-previous-icon {
  width: 24px;
}
@media only screen and (max-width: 768px) {
  .answerPage .layer-next-icon,
  .answerPage .layer-previous-icon {
    display: none;
  }
}
.answerPage .layer-message-item-main {
  overflow: visible;
}
.answerPage .layer-next-icon {
  right: -44px;
}
.answerPage layer-send-button {
  color: hsl(329.26829268, 44%, 58%) !important;
}
.answerPage .composer-button-panel__actions {
  display: flex;
  flex-direction: row;
}
.answerPage .composer-button-panel__actions button {
  margin: 0px;
  padding-left: 7px;
  padding-right: 7px;
}
.answerPage .composer-button-panel__actions button:disabled {
  cursor: initial;
}
.answerPage .composer-button-panel__actions button .material-icons__disabled {
  color: hsl(329.26829268, 67%, 92%);
}
.answerPage.product.fade-exit-active .panel-header {
  min-height: 260px !important;
}
.answerPage.product .question-panel-tablet {
  bottom: 69px !important;
}
.answerPage.product .question-panel-tablet layer-conversation-view {
  height: 100% !important;
}
.answerPage.mobile-view layer-message-list {
  -webkit-overflow-scrolling: initial !important;
}
.document-container + .answerPage.fade-exit-active layer-conversation-view {
  background-color: #ffffff !important;
  transition: none !important;
}
.document-container + .answerPage.fade-exit-active .panel-header {
  min-height: unset !important;
  transition: unset !important;
}
.answerPage.archived.fade-exit-active .panel-header {
  min-height: 153px !important;
}
.mobile-view .question-panel layer-conversation-view {
  height: 100%;
}
.layer-compose-bar-transition {
  transition: padding 200ms !important;
}
@keyframes opacity-hiding {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.select-drug,
.select-drug button#expand-button {
  background-color: hsl(329.26829268, 67%, 92%);
}
.select-drug button#expand-button i,
.select-drug .match-counter {
  color: #E065A4;
}
.select-drug .match-counter__circle {
  background-color: #E065A4;
}
.select-drug button#show-more-button {
  color: #E065A4;
}
.select-drug #select-drug-label {
  font-size: 11px;
  position: relative;
  top: -3px;
  color: #E065A4;
  cursor: pointer;
}
.select-drug--expanded #select-drug-label {
  display: none;
}
