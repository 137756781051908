/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-overview__title {
  flex-grow: 0;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.35px;
  text-align: left;
  color: #494d5b;
  margin-bottom: 0px !important;
}
.product-overview__manufacturer,
.product-overview__compound-component {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.35px;
  text-align: left;
  color: #aaacb2;
  margin: 0 15px;
}
.product-overview__compound-component {
  height: 21px;
  color: #777a84;
  margin-top: 12px;
  line-height: 1.31;
  font-weight: normal;
  letter-spacing: normal;
}
.product-overview__application-areas-title {
  margin: 12px 15px !important;
  font-size: 16px;
  font-weight: bold;
}
.product-overview__application-areas {
  background-color: white;
  max-width: 334px;
  min-width: 334px;
  border-radius: 10px;
  position: relative;
  height: 336px;
}
.product-overview__application-areas h3 {
  margin: 15px;
  color: #494d5b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-overview__multi-product {
  height: auto;
}
.product-overview__multi-product .product-overview__title {
  letter-spacing: normal;
  line-height: 1.5;
  height: 21px;
}
.product-overview__application-areas:nth-child(3n + 2) {
  margin: 0 10px;
}
.product-overview__multi-product:nth-child(3n + 2) {
  margin: 0 10px 10px;
}
.product-overview__link {
  position: absolute;
  bottom: 0;
  margin: 12px auto 15px 15px;
  font-weight: bold;
}
.product-overview__multi-product .product-overview__link {
  line-height: 1.88;
  letter-spacing: -0.35px;
  position: static;
  height: 30px;
}
.product-overview__subtitle {
  text-decoration: underline;
  margin: 12px auto 0 15px;
}
.product-overview__description-oneparagraph {
  margin: 0 11px 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}
.product-overview__description-oneparagraph,
.product-overview__description-oneparagraph > *,
.product-overview__description-multipleparagraphs > * {
  line-height: 24px !important;
}
.product-overview__description-multipleparagraphs {
  margin: 0 11px 0 15px;
  overflow-y: hidden;
  max-height: 190px;
  line-height: 24px !important;
}
.product-overview__description-multipleparagraphs p {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-overview__description > * {
  margin: 0;
}
.product-overview__description b {
  font-size: 16px;
}
.product-overview__description h4,
.product-overview__description h5,
.product-overview__description h6 {
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
}
.product-overview__subtitle,
.product-overview__description,
.product-overview__subtitle p,
.product-overview__description p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.35px;
  text-align: left;
  color: #494d5b;
}
p {
  line-height: 1.88;
}
@media only screen and (max-width: 767px) {
  .product-overview__application-areas {
    max-width: 334px;
    min-width: unset;
    width: 100%;
  }
  .product-overview__application-areas {
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .product-overview__application-areas:nth-child(3n + 2) {
    margin: 0;
  }
  .product-overview__multi-product:nth-child(3n + 2) {
    margin: 0 0 10px 0;
  }
  .product-overview__application-areas:nth-child(2n) {
    margin-left: 10px;
  }
  .product-overview__multi-product:nth-child(2n) {
    margin: 0 0 10px 10px;
  }
}
@media screen and (min-width: 768px) {
  .product-overview__application-areas {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .product-overview__application-areas {
    margin: 0 0 10px 0 !important;
  }
}
.product-overview__application-areas--filled {
  background-color: #E065A4;
}
.product-overview__application-areas--filled .product-overview__title,
.product-overview__application-areas--filled .product-overview__application-areas-title,
.product-overview__application-areas--filled .product-overview__description,
.product-overview__application-areas--filled .product-overview__link a {
  color: white;
}
