/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.question .suggestions li {
  color: #494d5b;
  background: white;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  transition: all 200ms ease-in-out;
  opacity: 0;
}
.question .suggestions li div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f0f1f3;
  padding: 10px 0px 11px 0;
  cursor: pointer;
}
.question .suggestions li div p {
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 67px;
  margin: 0;
}
.question .suggestions li button {
  border: none;
  right: 0;
  bottom: 0;
  color: #c1c3c9;
  padding: 0 6px 0 7px;
}
.question .suggestions li .icon-select-suggestion {
  cursor: pointer;
  font-size: 16px;
}
.question .suggestions.move li {
  opacity: 1;
}
