.document-container main .content .smpc-ema h1 {
  margin-top: 2em;
}
.document-container main .content .smpc-ema h2 {
  margin-top: 1.4em;
}
.document-container main .content .smpc-ema h3,
.document-container main .content .smpc-ema h4,
.document-container main .content .smpc-ema h5,
.document-container main .content .smpc-ema h6 {
  font-size: 16px;
  font-weight: normal;
}
.document-container main .content .smpc-ema h3,
.document-container main .content .smpc-ema h5 {
  text-decoration: underline;
}
.document-container main .content .smpc-ema h5,
.document-container main .content .smpc-ema h6 {
  font-style: italic;
}
.document-container main .content .smpc-ema h6 {
  margin-top: 1em;
  margin-bottom: 0;
}
.document-container main .content .smpc-ema h6 ~ p {
  margin-top: 0;
}
.document-container main .content .smpc-ema p {
  position: relative;
}
.document-container main .content .smpc-ema .ti-title {
  margin-top: 20px;
  margin-bottom: 0;
}
.document-container main .content .smpc-ema .no-margin-bottom {
  margin-bottom: 0 !important;
}
.document-container main .content .smpc-ema .add-margin-top {
  margin-top: 20px !important;
}
.document-container main .content .smpc-ema .m-l-s::before {
  content: ' ';
  white-space: pre;
}
.document-container main .content .smpc-ema .m-r-s::after {
  content: ' ';
  white-space: pre;
}
.document-container main .content .smpc-ema .bullet-point,
.document-container main .content .smpc-ema .dash-point {
  position: absolute;
  font-size: 24px;
}
.document-container main .content .smpc-ema .bullet-point ~ span,
.document-container main .content .smpc-ema .dash-point ~ span {
  display: block;
  margin-left: 30px;
}
.document-container main .content .smpc-ema .list-number {
  margin-right: 5px;
}
.document-container main .content .smpc-ema .font-small {
  font-size: 8px;
}
.document-container main .content .smpc-ema .flex-table-wrapper {
  overflow-x: auto;
  padding-bottom: 10px;
}
.document-container main .content .smpc-ema .flex-table-wrapper table {
  display: table;
  border-collapse: collapse;
}
.document-container main .content .smpc-ema .flex-table-wrapper table tbody > tr {
  display: table-row;
  padding: 2px 5px 0;
  border-right: 1px solid #f0f1f3;
}
.document-container main .content .smpc-ema .flex-table-wrapper table tbody > tr td {
  display: table-cell;
  padding: 3px;
}
