/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.search-area {
  width: 640px;
  position: relative;
  z-index: 1;
}
.search-area .search-area__navigation {
  display: none;
}
.search-area .search-area__input {
  border: solid 1px #e9ebef;
  border-radius: 10px;
  padding: 10px 80px 10px 10px;
  width: 100%;
  max-height: 50px;
  box-sizing: border-box;
  outline: none;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  text-align: left;
  caret-color: #E065A4;
  color: #494d5b;
  cursor: text;
}
.search-area .search-area__input--disabled {
  background-color: #f9f9f9;
}
.search-area .search-area__clear-search-button {
  position: absolute;
  top: 10px;
  right: 46px;
  padding: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.search-area .search-area__clear-search-button i {
  color: #ccced3;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.search-area .search-area__button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.search-area .search-area__button i {
  color: #E065A4;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.search-area .search-area__button--loading {
  position: static;
}
.search-area .search-area__button--loading .spinner {
  top: unset;
  margin: 0px;
  width: 22px;
  height: 22px;
}
@media only screen and (max-width: 767px) {
  .search-area .search-area__button--loading .spinner {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
  }
}
.search-area .search-area__input--focused {
  outline: none;
  border-radius: 10px 10px 0 0;
  border-bottom-style: none;
  padding: 10px 80px 11px 10px;
}
@media only screen and (max-width: 767px) {
  .search-area .search-area__input--focused .search-area__button i {
    margin-top: 3px;
  }
}
.search-area .search-area__input--focused::placeholder,
.search-area .search-area__input--focused:focus::placeholder {
  color: transparent;
}
.search-area .search-area__input::placeholder {
  color: #ccced3;
}
@media only screen and (min-width: 767px) {
  .search-area {
    max-height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .search-area--focused {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background-color: #ffffff;
  }
  .search-area--focused .search-area__navigation {
    display: flex;
    align-items: center;
    opacity: 0;
    height: 44px;
    border-bottom: 1px solid #e9ebef;
  }
  .search-area--focused .search-area__navigation .search-area__back-button {
    padding: 0 6px;
    margin-left: 4px;
    cursor: pointer;
  }
  .search-area--focused .search-area__navigation .search-area__back-button i {
    color: #E065A4;
    font-size: 24px;
  }
  .search-area--focused .search-area__input {
    border: none;
    opacity: 0;
    padding: 10px 80px 10px 10px !important;
    width: 100%;
    box-sizing: border-box;
    min-height: 45px;
    font-size: 21px;
    letter-spacing: -0.46px;
  }
  .search-area--focused .search-area__clear-search-button {
    top: 57px;
    opacity: 0;
  }
  .search-area--focused .search-area__clear-search-button i {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  .search-area--focused .search-area__button {
    top: 56px;
    opacity: 0;
  }
  .search-area--focused .search-area__button i {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  .search-area--focused .auto-suggest {
    opacity: 0;
    max-width: 100%;
    border: none;
  }
  .search-area--focused .auto-suggest .auto-suggest__example {
    padding: 17px 35px 0 40px;
    font-size: 16px;
    line-height: 20px;
  }
  .search-area--visible .search-area__navigation,
  .search-area--visible .search-area__input,
  .search-area--visible .search-area__clear-search-button,
  .search-area--visible .search-area__button,
  .search-area--visible .auto-suggest {
    opacity: 1;
  }
}
@media only screen and (max-width: 680px) {
  .search-area {
    width: 100%;
    max-width: unset;
  }
}
@media only screen and (max-width: 375px) {
  .search-area .search-area__input {
    font-size: 16px;
  }
}
