/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
/* Conversation Item */
/* Conversation List */
.question {
  position: relative;
  padding: 14px 16px;
  box-sizing: border-box;
  height: 305px;
  background-color: #ffffff;
  margin: 0 auto 3px auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .question {
    height: 335px;
  }
}
@media screen and (max-width: 614px) {
  .question {
    height: 368px;
  }
}
@media screen and (max-width: 375px) {
  .question {
    height: 340px;
  }
}
@media screen and (max-width: 340px) {
  .question {
    height: 376px;
  }
}
.question--first-time {
  height: 305px;
}
@media screen and (max-width: 767px) {
  .question--first-time {
    height: 345px;
  }
}
@media screen and (max-width: 375px) {
  .question--first-time {
    height: 315px;
  }
}
@media screen and (max-width: 365px) {
  .question--first-time {
    height: 318px;
  }
}
@media screen and (max-width: 340px) {
  .question--first-time {
    height: 396px;
  }
}
.question .fixed-send {
  display: none;
}
.question .bigButtonBar__message,
.question .bigButtonBar button.short-cut {
  height: 26px;
  font-size: 15px;
  margin: 10px 10px 0 0;
  color: #aaacb2;
}
.question .bigButtonBar button.short-cut {
  padding: 0 6px;
  border-radius: 5px;
  border: 0px;
  vertical-align: center;
  white-space: nowrap;
  width: auto;
  background-color: #f6f6f7;
}
.question .bigButtonBar button.short-cut:hover {
  color: #494d5b;
}
@media (hover: none) {
  .question .bigButtonBar button.short-cut:hover {
    color: #ccced3;
  }
}
.question .bigButtonBar button.short-cut:last-child {
  margin-right: 0;
}
.question .bigButtonBar button.short-cut__more {
  color: #E065A4;
}
.question.open .questionInput {
  opacity: 1;
  margin-top: 14px;
}
.question.open .questionInput:disabled {
  background-color: rgba(0, 0, 0, 0);
}
.question.open .welcome .logo img {
  opacity: 1;
  transform: scale(1);
}
.question.open .welcome .text {
  opacity: 1;
}
.question .questionInput {
  width: 100%;
  resize: none;
  height: 119px;
  caret-color: #E065A4;
  margin-top: 24px;
}
@media screen and (max-width: 375px) {
  .question .questionInput {
    height: 91px;
  }
}
.question .questionInput .content-editable__input {
  word-wrap: break-word;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 11;
}
.question .questionInput .content-editable__input::-webkit-scrollbar {
  width: 0 !important;
}
.question .bigButtonBarVisible {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s 0s, opacity 200ms ease-in-out;
}
.question .suggestions {
  min-height: max-content;
}
.question .suggestions.move + .bigButtonBar {
  transition-delay: 200ms;
}
@media screen and (max-width: 1035px) {
  .question .bigButtonBar button.short-cut,
  .question .bigButtonBar__message {
    margin-top: 10px;
    margin-bottom: 0;
  }
}
.question.question-product {
  height: 174px;
}
@media screen and (max-width: 614px) {
  .question.question-product {
    height: 202px;
  }
}
@media screen and (max-width: 375px) {
  .question.question-product .questionInput {
    height: unset;
  }
}
.question:not(.question-product) .questionInput {
  min-height: 119px;
}
@media screen and (max-width: 375px) {
  .question:not(.question-product) .questionInput {
    min-height: 91px;
  }
}
.question.question-product .questionInput {
  height: 65px;
  min-height: 65px;
}
.question-mobile-view-transition {
  transition: height 400ms, margin-top 400ms, position 400ms, top 400ms !important;
}
.question-mobile-view-transition-header {
  transition: min-height 400ms, height 400ms !important;
}
.question--invisible {
  opacity: 0;
}
.question--collapsed {
  padding: 1px;
}
.question--adaptive {
  height: unset !important;
}
.component--hidden {
  display: none !important;
}
.component--invisible {
  opacity: 0;
}
.question-change-url-transition {
  transition: height 400ms 200ms ease-in-out, padding 400ms 200ms ease-in-out !important;
}
.question-mobile-view {
  margin: 0;
  padding-top: 0px !important;
  background-color: #ffffff !important;
}
.question-mobile-view .questionInput {
  height: 110px;
  margin-top: 0px !important;
}
.question-mobile-view .content-editable__input {
  padding-top: 10px;
}
.service-widget .question-mobile-view .content-editable__input {
  padding-left: 25px;
}
.question-mobile-view-relative {
  top: 0 !important;
  margin: 0 !important;
  position: relative;
}
.question-mobile-view-fixed {
  top: 0 !important;
  margin: 0 !important;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 4px 0;
  z-index: 1;
}
.questionFixed .question-mobile-view .content-editable__input {
  padding-top: 10px;
}
.welcome {
  margin-left: 6px;
}
.welcome .text {
  color: #494d5b;
  border-radius: 10px;
  background: #f0f1f3;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 6px 9px 5px 10px;
  vertical-align: bottom;
  transition: opacity 200ms 800ms ease-in-out;
  opacity: 0;
}
.welcome .text:last-child {
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .welcome .logo {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .welcome {
    position: relative;
  }
  .welcome .logo {
    position: absolute;
    top: 3px;
    left: -35px;
  }
}
.welcome .logo {
  margin: 0 10px 0 2px;
}
.welcome .logo img {
  transition: all 400ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  transform: scale(0);
}
.questionFixed .welcome {
  position: fixed;
  top: 10px;
}
.questionFixed .welcome .text:last-child {
  margin-top: 0px;
}
.questionFixed .question .fixed-send {
  position: absolute;
  bottom: 0;
  right: calc(50% - 348px);
  color: #E065A4;
  cursor: pointer;
}
.questionFixed .question .fixed-send.visible {
  display: block;
}
@media screen and (max-width: 700px) {
  .questionFixed .question .fixed-send {
    right: 2px;
  }
}
.questionFixed .question:not(.question-product) .questionInput {
  max-height: 119px;
  max-width: 668px;
}
@media screen and (max-width: 768px) {
  .questionFixed .question:not(.question-product) .questionInput {
    height: 115px;
    min-height: 115px !important;
  }
}
.questionFixed:not(.fixed) .question {
  background-color: rgba(0, 0, 0, 0);
}
.questionFixed .questionInput {
  position: fixed;
  top: 40px;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .questionFixed .questionInput {
    top: 69px;
    width: calc(100% - 32px);
  }
}
.questionFixed .bigButtonBar {
  position: absolute;
  bottom: 19px;
  left: 16px;
  right: 16px;
}
.productPage .questionFixed .question .questionInput {
  max-height: 65px;
}
@media screen and (max-width: 767px) {
  .mobile-view .questionFixed .question:not(.question-product) .questionInput {
    height: 90px;
    min-height: 90px !important;
  }
}
.question-mobile-view .questionInput .content-editable__input div {
  height: 82px !important;
  overflow: scroll;
}
@media screen and (max-width: 767px) {
  .question-mobile-view .question:not(.question-product) .questionInput {
    height: 110px !important;
    min-height: 110px !important;
  }
}
.question-panel {
  background: white;
  z-index: 3;
}
.xircles-status {
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 8px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: -3px;
}
.xircles-status.open {
  background: #8DD2CF;
}
.xircles-status.solved {
  background: #8DD2CF;
}
.xircles-status.in-progress {
  background: #8DD2CF;
}
.xircles-status.answer-delivered {
  background: #8DD2CF;
}
.xircles-status.chat {
  background: #E065A4;
}
.xircles-status.closed {
  background: #E065A4;
}
.xircles-status.deleted {
  background: #E065A4;
}
.questionPage {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
}
.questionPage .header-background {
  position: relative;
}
.questionPage .deleted-requests__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}
.questionPage .deleted-requests__content {
  position: relative;
  opacity: 0;
  max-width: 660px;
  margin: auto;
}
.questionPage .deleted-requests__content--animated {
  transition: opacity 200ms;
}
.questionPage .deleted-requests__subtitle {
  text-align: center;
  margin: 0 0 25px 0;
  padding-top: 4px;
  color: #E065A4;
}
.questionPage .deleted-requests__back-button {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  font-size: 1.17em;
  font-weight: bold;
  color: #E065A4;
  cursor: pointer;
}
.questionPage .deleted-requests__back-button i {
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .questionPage .panel-header {
    min-height: 98px;
  }
}
@media screen and (min-width: 1035px) {
  .questionPage .panel-header {
    min-height: 126px;
  }
}
.questionPage.fade-exit-active .panel-header {
  opacity: 0;
  animation: unset !important;
  min-height: 0 !important;
}
.questionPage.fade-exit-active .panel-header .login,
.questionPage.fade-exit-active .panel-header .menu,
.questionPage.fade-exit-active .panel-header .menu-button {
  display: none;
}
.questionPage.fade-exit-active .panel-header .title {
  animation: unset !important;
  margin-top: 0 !important;
  transition: margin 400ms 200ms ease-in-out !important;
}
.questionPage.fade-exit-active .panel-header .title h1 {
  animation: unset !important;
  margin: 0 !important;
  transition: all 400ms 200ms ease-in-out !important;
}
.questionPage.fade-exit-active .deleted-requests__wrapper {
  opacity: 0;
}
.questionPage.fade-exit-active .filter-wrapper {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.questionPage.fade-exit-active .header-background .question {
  animation: unset !important;
  opacity: 0;
}
.questionPage.fade-exit-active .header-background .question.question-mobile-view-fixed,
.questionPage.fade-exit-active .header-background .question.question-mobile-view {
  opacity: 1;
  animation: unset;
}
.questionPage.fade-exit-active .header-background .question.question-mobile-view-fixed .questionInput,
.questionPage.fade-exit-active .header-background .question.question-mobile-view .questionInput {
  opacity: 0;
  transition: opacity 0ms;
}
.questionPage.fade-exit-active .scrollPane > div,
.questionPage.fade-exit-active .scrollPane footer,
.questionPage.fade-exit-active .scrollPane h2,
.questionPage.fade-exit-active .scrollPane layer-conversation-item:not(.layer-selected-item) {
  transition: opacity 0ms;
  opacity: 0;
}
.questionPage.fade-exit-active .scrollPane {
  transition: all 400ms 200ms ease-in-out;
  background-color: #ffffff;
  height: 100vh;
}
.questionPage.fade-exit-active .scrollPane layer-conversation-list {
  transition: all 400ms 200ms ease-in-out;
  background-color: #ffffff;
}
.questionPage.fade-exit-active .scrollPane layer-conversation-list .layer-list-meta {
  display: none;
}
.questionPage.fade-exit-active .scrollPane .layer-selected-item {
  transition: top 0ms 600ms ease-in-out, left 0ms 600ms ease-in-out;
  animation: opacityChange;
  animation-delay: 200ms;
  animation-duration: 500ms;
}
.questionPage.fade-exit-active .scrollPane .layer-selected-item .xircles-actions {
  opacity: 0;
}
.questionPage.fade-exit-active .menu-panel,
.questionPage.fade-exit-active .fixed-send {
  transition: opacity 200ms;
  opacity: 0;
}
.questionPage.fade-exit-active .fixed {
  animation: unset !important;
}
.questionPage.fade-exit-active .fixed .panel-header {
  animation: unset !important;
  opacity: 0;
  min-height: 0 !important;
  height: 0;
}
.questionPage.fade-exit-active .fixed .header-background .question {
  animation: unset !important;
}
.questionPage.fade-exit-active .fixed .question {
  animation: unset !important;
  opacity: 1;
}
.questionPage.fade-exit-active .fixed .welcome,
.questionPage.fade-exit-active .fixed .questionInput {
  animation: unset !important;
  transition: opacity 200ms;
  opacity: 0;
}
.questionPage layer-conversation-item-date {
  display: none;
}
.questionPage .layer-conversation-list--disabled {
  pointer-events: none;
}
.questionPage .xircles-confirm {
  border-radius: 15.5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  top: -15px;
  right: 25%;
  opacity: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
}
.questionPage .xircles-confirm.open {
  animation: appear 0.2s ease-in forwards;
}
.questionPage .xircles-confirm.close {
  animation: disappear 0.2s ease-out forwards;
}
.questionPage .xircles-confirm .xircles-confirm-text {
  display: inline-block;
  margin: 0 15px 0 11px;
}
.questionPage .xircles-confirm .xircles-confirm-action {
  width: 24px;
  height: 24px;
  margin: 3px 5px 4px;
  display: inline-block;
  cursor: pointer;
}
.questionPage .xircles-confirm .xircles-confirm-action.confirm-yes {
  color: #E065A4;
}
.questionPage .xircles-confirm .xircles-confirm-action.confirm-no {
  color: #ccced3;
}
.questionPage .xircles-actions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background: #494d5b;
}
.questionPage .xircles-actions i {
  display: block;
  margin-bottom: 10px;
}
.questionPage .xircles-actions li {
  margin: 0;
  color: white;
  font-size: 12px;
  width: 64px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
}
.questionPage .xircles-actions ul {
  list-style: none;
  display: flex;
  float: right;
  margin: 40px 12px 40px 10px;
}
.questionPage layer-conversation-item layer-avatar,
.questionPage layer-conversation-item .layer-conversation-title-row,
.questionPage layer-conversation-item .xircles-handle {
  transition: opacity 30ms 200ms ease-out;
  opacity: 1;
}
.questionPage layer-conversation-list {
  background-color: #f0f1f3;
  margin: 0 auto 0 auto;
  padding: 10px 0;
  overflow: hidden;
  opacity: 0;
  display: none;
  transition: opacity 200ms ease-in-out;
}
.questionPage layer-conversation-list + footer {
  margin-top: 82px;
}
@media screen and (min-width: 1035px) {
  .questionPage layer-conversation-list {
    width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage layer-conversation-list {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage layer-conversation-list {
    max-width: 335px !important;
  }
}
.questionPage .layer-conversation-item-wrapper {
  overflow: hidden;
  height: 100%;
}
.questionPage .layer-conversation-item-wrapper .xircles-footer {
  display: none;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-handle {
  display: none;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content {
  box-sizing: border-box;
  width: available;
  height: 110px;
  padding-right: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content layer-conversation-last-message {
  max-width: 270px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content .layer-conversation-title-row {
  position: relative;
  height: 20px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content layer-conversation-title {
  color: #8DD2CF;
  width: min-content;
  max-width: min-content;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content .conversation-name {
  position: absolute;
  top: 4px;
  color: #494d5b;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 53px;
  align-self: flex-end;
  position: relative;
  padding-top: 10px;
  font-size: 12px;
  color: #aaacb2;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer::before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  left: -10px;
  border: 1px solid #d8d8d8;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  color: white;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__first {
  margin-right: 10px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__first,
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__second {
  padding: 3px 10px;
  background: #aaacb2;
  border-radius: 12px;
}
.questionPage layer-conversation-item {
  margin: 10px 5px 0;
  position: relative;
  display: inline-block;
  border-bottom: none;
  transition: opacity 30ms ease-out, width 200ms 30ms ease-out, height 200ms 30ms ease-out, margin-top 200ms 30ms ease-out;
  width: 335px;
  height: 126px;
  opacity: 1;
}
.questionPage layer-conversation-item.opening {
  width: 335px;
}
.questionPage layer-conversation-item.layer-item-filtered {
  display: none;
}
@media screen and (max-width: 767px) {
  .questionPage layer-conversation-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.questionPage layer-conversation-item layer-avatar {
  margin-right: 11px;
}
.questionPage layer-conversation-item .xircles-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.questionPage layer-conversation-item .xircles-handle div {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 5px;
  height: 40px;
  border-radius: 2.5px;
  background: #f0f1f3;
  transform: translateY(-50%);
}
.questionPage layer-conversation-item .xircles-handle:hover div {
  animation: handleConversationCard 700ms ease-in-out;
}
.questionPage layer-conversation-item .layer-list-item .layer-conversation-left-side .layer-replaceable-inner {
  transition: width 230ms 30ms ease-in-out;
  width: auto;
}
.questionPage layer-conversation-item.open .layer-list-item {
  left: -212px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.questionPage layer-conversation-item layer-conversation-last-message a {
  color: #494d5b;
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item {
  align-items: flex-start;
  height: 100%;
  padding: 10px 20px 10px 10px;
  border-radius: 10px;
  background: white;
  z-index: 1;
  transition: transform 200ms ease-in-out, left 200ms ease-in-out, box-shadow 200ms ease-in-out, border-radius 230ms 30ms ease-in-out;
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item layer-conversation-last-message {
  display: block;
  white-space: normal;
  color: #494d5b;
  font-weight: bold;
  line-height: 1.17;
  margin-top: 0;
  height: 63px;
  font-size: 18px;
  padding-top: 0;
  padding-left: 0;
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item layer-conversation-title {
  font-size: 12px;
  max-width: 140px;
}
.questionPage layer-conversation-item.layer-size-large .layer-conversation-title-row {
  position: absolute;
  bottom: 0;
}
.questionPage layer-conversation-item.layer-size-large .layer-conversation-item-wrapper__operator .layer-list-item {
  padding: 10px;
}
.questionPage layer-conversation-item.layer-size-large .layer-conversation-item-wrapper__operator .layer-list-item .conversation-id-label {
  position: absolute;
  top: 10px;
  right: 0;
}
.questionPage layer-conversation-item .layer-conversation-title-row layer-conversation-title {
  width: auto;
  color: #8DD2CF;
  font-weight: bold;
}
.questionPage .right-panel {
  display: none;
}
.questionPage .left-panel {
  -ms-flex: 1 1;
  flex: 1 1;
  max-width: none;
  overflow: auto;
  background-color: white;
}
.questionPage .left-panel .counter-wrapper {
  background-color: #f0f1f3;
  width: 100%;
}
.questionPage .left-panel .counter-wrapper #counter {
  display: block;
  margin: 0 auto;
  max-width: 335px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #494d5b;
}
@media screen and (max-width: 1034px) and (min-width: 768px) {
  .questionPage .left-panel .counter-wrapper #counter {
    max-width: 690px;
  }
}
@media screen and (min-width: 1035px) {
  .questionPage .left-panel .counter-wrapper #counter {
    max-width: 1035px;
  }
}
.questionPage .question {
  width: 100%;
  z-index: 15;
  xtransition: top 30ms 200ms ease-out;
  xtop: 0;
}
.questionPage .open layer-conversation-item layer-avatar,
.questionPage .open layer-conversation-item .layer-conversation-title-row,
.questionPage .open layer-conversation-item .xircles-handle {
  transition: opacity 30ms ease-out;
  opacity: 0;
}
.questionPage .open layer-conversation-item .layer-list-item {
  border-radius: 0;
}
.questionPage .open layer-conversation-item .layer-list-item .layer-conversation-left-side .layer-replaceable-inner {
  width: 0px;
}
.questionPage .open layer-conversation-item.layer-size-large .layer-list-item layer-conversation-last-message {
  transition: all 200ms ease-out;
  width: 100%;
  font-size: 24px;
  padding-top: 43px;
  padding-left: 10px;
}
.questionPage .open layer-conversation-item.layer-selected-item {
  transition: opacity 30ms 200ms ease-out, width 200ms ease-out, height 200ms ease-out, margin-top 30ms ease-out;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.questionPage .open layer-conversation-item:not(.layer-selected-item) {
  opacity: 0;
}
.questionPage.fade-exit .question {
  xtransition: top 30ms ease-out;
  xtop: -100px;
}
.questionPage.fade-enter-done .panel-header,
.questionPage.fade-enter-done .question {
  animation: opacityChangeAppearing;
  animation-duration: 200ms;
}
.questionPage.fade-enter-done .scrollPane > div,
.questionPage.fade-enter-done .scrollPane footer,
.questionPage.fade-enter-done .scrollPane h2,
.questionPage.fade-enter-done .scrollPane layer-conversation-item {
  animation: opacityChangeAppearing;
  animation-duration: 200ms;
}
.questionPage.deleted-requests.fade-enter-done .panel-header,
.questionPage.deleted-requests.fade-enter-done .question {
  animation: none;
}
.questionPage .fixed .question {
  position: fixed;
  top: -126px;
  transition: none;
  max-width: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 4px 0;
}
.questionPage .fixed .question .bigButtonBar {
  display: none;
}
.questionPage .fixed .question .welcome {
  left: 50%;
  max-width: 700px;
  margin-left: -328px;
}
@media screen and (max-width: 700px) {
  .questionPage .fixed .question .welcome {
    margin-left: 0;
    left: 22px;
  }
}
.questionPage .fixed .question .questionInput {
  max-width: 668px;
  left: 50%;
  margin-left: -334px;
}
@media screen and (max-width: 700px) {
  .questionPage .fixed .question .questionInput {
    margin-left: 0;
    left: 16px;
  }
}
.questionPage .fixed .question .suggestions {
  max-width: 668px;
  left: 50%;
  margin-left: -344px;
}
.questionPage .fixed .scrollPane {
  padding-top: 305px;
}
@media screen and (max-width: 767px) {
  .questionPage .fixed .scrollPane {
    padding-top: 295px;
  }
}
@media screen and (max-width: 504px) {
  .questionPage .fixed .scrollPane {
    padding-top: 335px;
  }
}
@media screen and (max-width: 375px) {
  .questionPage .fixed .scrollPane {
    padding-top: 340px;
  }
}
.questionPage .fixed .scrollPane--first-time {
  padding-top: 305px;
}
@media screen and (max-width: 767px) {
  .questionPage .fixed .scrollPane--first-time {
    padding-top: 345px;
  }
}
@media screen and (max-width: 365px) {
  .questionPage .fixed .scrollPane--first-time {
    padding-top: 318px;
  }
}
.questionPage .scrollPane {
  z-index: 0;
  background-color: #f0f1f3;
}
.questionPage .scrollPane h2.question-title {
  transition: opacity 200ms ease-in-out;
  text-align: center;
  display: none;
  opacity: 0;
}
.questionPage .scrollPane h2.question-title:first-of-type {
  margin: 35px 0 10px 0;
}
.questionPage .scrollPane h2.question-title:last-of-type {
  margin: 45px 0 10px 0;
}
.questionPage .scrollPane--operator {
  min-height: calc(100vh - 254px);
}
.questionPage .scrollPane__deleted-requests {
  min-height: calc(100vh - 165px);
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane__deleted-requests {
    min-height: calc(100vh - 158px);
  }
}
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane__deleted-requests {
    min-height: calc(100vh - 186px);
  }
}
.questionPage.productPage .fixed .question {
  top: -50px;
}
@media screen and (max-width: 768px) {
  .questionPage.productPage .panel-header {
    min-height: unset;
  }
}
@media screen and (min-width: 1035px) {
  .questionPage.productPage .panel-header {
    min-height: unset;
  }
}
.mobile-view .fixed .question {
  max-height: 373px;
}
@media screen and (max-width: 767px) {
  .questionPage:not(.mobile-view) .fixed .question:not(.question-product) {
    height: 325px !important;
    max-height: 325px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage.firefox:not(.mobile-view) .fixed .question:not(.question-product) {
    height: 328px !important;
    max-height: 328px;
  }
}
.menu-button {
  position: fixed;
  right: 10px;
  top: 10px;
}
@media screen and (max-width: 767px) {
  .menu-button {
    top: 0;
    right: 0;
  }
}
.menu-button button {
  padding: 10px;
  color: #666666;
  font-weight: bold;
  cursor: pointer;
  border-width: 0;
}
@media only screen and (min-width: 1035px) {
  .menu-button button {
    width: 80px;
    text-align: right;
  }
}
.questionPage.productPage .menu-button {
  position: absolute;
  top: 15px;
}
layer-presence {
  display: none !important;
}
layer-identity-item.layer-size-large .layer-list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: auto;
}
layer-conversation-item.removed-item {
  animation: removed-item-animation 0.2s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards !important;
}
layer-conversation-item .layer-list-item .layer-conversation-item-content {
  position: relative;
  height: 100%;
  padding-right: 36px;
}
#root > div {
  width: 100%;
}
@keyframes handle {
  0%,
  100% {
    right: 20px;
  }
  28%,
  71% {
    right: 25px;
  }
}
@keyframes handleConversationCard {
  0%,
  100% {
    right: 5px;
  }
  28%,
  71% {
    right: 10px;
  }
}
@keyframes removed-item-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  99% {
    opacity: 0;
    transform: scale(0);
    width: 335px;
    height: 126px;
    margin: 10px 5px 0;
  }
  100% {
    opacity: 0;
    transform: scale(0);
    height: 0;
    width: 0;
    margin: 0;
  }
}
@keyframes appear {
  0% {
    width: auto;
    height: 31px;
    opacity: 0.6;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    width: auto;
    height: 31px;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
    transform: scale(1);
    width: auto;
    height: 31px;
  }
  99% {
    opacity: 0.6;
    transform: scale(0.6);
  }
  100% {
    opacity: 0.6;
    transform: scale(0);
    width: auto;
    height: 31px;
  }
}
@keyframes headerMinimize {
  100% {
    transition: height;
    transition-timing-function: ease-in-out;
  }
}
@keyframes headerMinimizeMobile {
  100% {
    transition: height;
    transition-timing-function: ease-in-out;
    height: 37px;
  }
}
@keyframes opacityChange {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transition: opacity;
    transition-timing-function: ease-in-out;
  }
}
@keyframes opacityChangeAppearing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transition: opacity;
    transition-timing-function: ease-in-out;
  }
}
.title-fake {
  position: absolute;
  top: -1000000px;
  left: -1000000px;
}
