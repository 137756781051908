/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.menu-panel {
  z-index: 101;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 275px;
  background: #494d5b;
  color: #ccced3;
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-weight: lighter;
  transition: opacity 200ms ease-in-out;
}
.menu-panel footer {
  font-size: 13px;
  line-height: 1.63;
  position: absolute;
  bottom: 22px;
}
.menu-panel footer .menu-content-button {
  margin-top: 10px;
  position: relative;
}
.menu-panel footer .menu-content-button a {
  text-decoration: underline;
  color: #ccced3;
}
.menu-panel footer div {
  padding-left: 20px;
  margin-top: 20px;
}
.menu-panel button {
  background: #494d5b;
  color: #ffffff;
}
.menu-panel ul {
  list-style: none;
  padding-left: 0px;
  margin-top: 60px;
}
.menu-panel ul .menu-account-button {
  position: relative;
  margin-top: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 375px) {
  .menu-panel ul .menu-account-button {
    margin-top: 20px;
  }
}
.menu-panel ul .menu-account-button i {
  margin-right: 7px;
}
.menu-panel ul .menu-account-button a {
  display: flex;
  align-items: center;
  color: #ccced3;
}
.menu-panel ul .menu-account-button button {
  padding: 0;
  color: #ccced3;
  font-weight: lighter;
  cursor: pointer;
}
.menu-panel ul .menu-account-button.menu-login-button,
.menu-panel ul .menu-account-button.menu-logout-button {
  display: none;
  margin: 33px 20px 0 20px;
  padding: 0;
}
.menu-panel ul .menu-account-button.menu-login-button button,
.menu-panel ul .menu-account-button.menu-logout-button button {
  max-height: 30px;
  max-width: 235px;
  color: white;
  border-radius: 35px;
  border: 1px solid white;
  padding: 8px 14px 7px 13px;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .menu-panel ul .menu-account-button.menu-login-button,
  .menu-panel ul .menu-account-button.menu-logout-button {
    display: block;
  }
}
.menu-panel .menu-logout-button {
  display: block !important;
  margin: 20px !important;
}
.menu-panel.open-menu {
  opacity: 1;
  pointer-events: all;
}
.menu-panel.open-menu .close {
  transform: rotate(0);
  color: #ffffff;
}
.menu-panel.close-menu {
  opacity: 0;
  pointer-events: none;
}
.menu-panel .close {
  transition: all 200ms ease-in-out;
  transform: rotate(-90deg);
}
.menu-fixed {
  position: fixed !important;
}
.menu-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
}
.menu-panel-overlay__shown {
  display: block;
}
