.dialog {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.6);
  z-index: 500;
}
.dialog.hidden {
  display: none;
}

.dialog > * {
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .dialog .dialog-container {
    width: 60%;
  }
}
@media screen and (min-height: 650px) {
  .dialog .dialog-container {
    height: 60%;
  }
}

@media screen and (max-width: 700px) {
  .dialog .dialog-container {
    width: 90%;
  }
}

@media screen and (max-height: 650px) {
  .dialog .dialog-container {
    height: 95%;
  }
}

.dialog .dialog-container {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;

  border: solid 2px #777;
  background-color: white;

  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.dialog .conversation-values {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dialog .conversation-values input {
  flex-grow: 1;
  width: 100px; /* flexbox workaround */
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin: 10px;
  border-width: 0px;
}

.dialog .conversation-values svg {
  margin-right: 10px;
  font-size: 36px;
  color: rgba(0, 0, 0, 0.5);
}

.dialog-dirty.dialog .conversation-values svg {
  color: rgba(25, 165, 228, 0.8);
}

.dialog-dirty.dialog .conversation-values svg:hover {
  color: rgb(25, 165, 228);
}

.dialog .button-panel {
  background-color: rgba(100, 100, 100, 0.6);
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dialog .button-ok {
  width: 80px;
  font-weight: bold;
  font-size: 1.2em;
}

.dialog .button-panel input {
  background-color: transparent;
  border-width: 0px;
  margin: 6px 8px;
  padding: 4px 6px;
  border-radius: 4px;
  flex-grow: 1;
}

.dialog .button-panel input:focus {
  background-color: white;
}

.dialog .button-panel input::placeholder {
  color: white;
}

.dialog .button-panel input::placeholder {
  color: white;
}

.dialog layer-identity-list {
  flex-grow: 1;
  height: 100px; /* Flexbox workaround */
}

.panel-section-header {
  font-size: 16px;
  padding: 8px 12px;
  background-color: #ebebeb;
}
