/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.mmi-banner {
  background-color: #E065A4;
  box-sizing: border-box;
  min-height: 74px;
  padding: 10px;
}
.mmi-banner--fixed {
  z-index: 22;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.mmi-banner__content {
  margin: 0 auto;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  max-width: 680px;
}
.mmi-banner__content a {
  color: #fff !important;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .mmi-banner__content {
    font-size: 11px;
  }
}
