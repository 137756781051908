/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__container {
  height: 85% !important;
  max-width: 50vw !important;
  width: 50%;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__surface {
  border-radius: unset;
  max-width: 100% !important;
  width: 100%;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__title {
  height: 0;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__title div:nth-of-type(2) {
  position: absolute !important;
  z-index: 1;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .route-section {
  padding: 0 20px 20px 20px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__wrapper {
  height: available;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__input {
  margin-top: 14px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__input input {
  border: none;
  text-decoration: none;
  margin-bottom: 36px;
  cursor: pointer;
  width: 95%;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #494d5b;
  outline: none;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__input input:disabled {
  background: white;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__input input::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section {
  height: calc(85vh - 100px);
  overflow-y: auto;
  margin-bottom: 20px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item {
  position: relative;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.22);
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__body {
  width: calc(100% - 130px);
  min-height: 55px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__body h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #4a4d5a;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__body p {
  margin: 10px 0 15px 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #4a4d5a;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__button-box {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  flex-direction: row;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__button-box span {
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #E065A4;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__button-box button {
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 47.5px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__button-box button:nth-of-type(1) {
  margin-right: 20px;
}
.xircles .xircles-operator-dialog__provide-content .mdc-dialog__content .provide-content__result-section--item__button-box i {
  color: #E065A4;
  margin-bottom: 5px;
}
