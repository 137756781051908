.panel-header {
  box-sizing: border-box;
  border: 0px solid #e4e9ec;
  display: -webkit-flex;
  display: flex;
  min-height: 58px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 8px;
}
.panel-header .title {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin-top: 40px;
}

.productPage .panel-header .title {
  margin: 23px 0;
}

.panel-header a {
  cursor: pointer;
  font-size: 18px;
  padding: 6px 4px 4px 6px;
  margin-left: 10px;
  color: #eee;
}
.panel-header a:hover {
  color: #fff;
}
.panel-header layer-avatar span.layer-text-avatar {
  color: rgb(110, 116, 143);
  background: transparent;
  border-color: rgba(110, 116, 143, 0.4);
  border-width: 0.5px;
}
.conversations-header {
  text-align: center;
  padding: 0;
}
.conversation-header a {
  color: rgb(0, 155, 119);
}

.conversation-header a:hover {
  color: rgba(0, 155, 119, 0.7);
}

.conversations-header layer-presence {
  margin-right: 10px;
}

.conversations-header .logout {
  margin-right: 8px;
}

.welcome-header {
  background: transparent;
  justify-content: center;
}

.xircle-header {
  background: transparent;
  justify-content: flex-end;
}
