/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-xircle-page__drug-intro,
.product-xircle-page__news,
.product-xircle-page__content {
  background-color: #f0f1f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 28px;
}
.product-xircle-page__brand-name {
  margin: 0;
  padding: 51px 0 0;
  font-family: Roboto;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #E065A4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-xircle-page__compound-name {
  color: #494d5b;
  padding: 4px 0 54px;
  font-family: Georgia;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}
.product-xircle-page__drug-intro-text {
  max-width: 851px;
  padding: 26px 0 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__drug-intro-text {
    max-width: 675px;
  }
}
@media screen and (max-width: 767px) {
  .product-xircle-page__drug-intro-text {
    max-width: 334px;
  }
}
.product-xircle-page__header {
  padding-top: 26px;
}
.product-xircle-page__header .brand-header__welcome {
  min-height: unset;
}
.product-xircle-page__drug {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__drug {
    flex-direction: column;
  }
}
.product-xircle-page__dosage-forms {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.product-xircle-page__dosage-forms li {
  margin-bottom: 15px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
}
.product-xircle-page__drug-card {
  min-height: 330px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  max-width: 509px;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__drug-card {
    max-width: 334px;
  }
}
@media screen and (max-width: 767px) {
  .product-xircle-page__drug-card {
    width: 100%;
  }
  .product-xircle-page__drug-card:first-of-type {
    margin-bottom: 10px;
  }
}
.product-xircle-page__drug-card:last-of-type {
  padding: 40px 0px 29px 0px;
  align-self: stretch;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__drug-card:last-of-type {
    align-self: center;
    margin-left: 0px;
  }
}
.product-xircle-page__drug-card:last-of-type img {
  height: 241px;
  object-fit: contain;
}
.product-xircle-page__safety_hints {
  list-style-type: none;
  color: black;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  padding: 0;
  margin: 10px 0 0 0;
}
@media screen and (min-width: 1034px) {
  .product-xircle-page__safety_hints {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.product-xircle-page__safety_hints li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.product-xircle-page__safety_hints li:nth-child(odd) {
  margin-right: 20px;
}
.product-xircle-page__safety_hints_image {
  margin-right: 5px;
}
.product-xircle-page__links {
  list-style-type: none;
  margin: 15px 0 0 0;
  padding: 0;
}
.product-xircle-page__links li:not(:last-of-type) {
  margin-bottom: 10px;
}
.product-xircle-page__links a {
  color: #494d5b;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}
.product-xircle-page__links a i {
  vertical-align: bottom;
}
.product-xircle-page__links li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product-xircle-page__link_icon {
  font-size: 21px;
  margin-left: 5px;
}
.product-xircle-page__announcement {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px 28px;
  display: flex;
}
.product-xircle-page__announcement p:first-of-type {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  margin: 0 0 10px 0;
}
.product-xircle-page__announcement h2 {
  margin: 0;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__announcement h2 {
    max-width: 675px;
  }
}
@media screen and (max-width: 767px) {
  .product-xircle-page__announcement h2 {
    max-width: 334px;
  }
}
.product-xircle-page__announcement-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  max-width: 851px;
  margin: 10px 0;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__announcement-text {
    max-width: 675px;
  }
}
@media screen and (max-width: 767px) {
  .product-xircle-page__announcement-text {
    max-width: 334px;
  }
}
.product-xircle-page__announcement-button {
  color: #494d5b;
  border-radius: 35px;
  border: 1px solid #494d5b;
  padding: 6px 12px 6px 11px;
  font-size: 14px;
  cursor: pointer;
}
.product-xircle-page__news {
  padding-bottom: 195px;
}
.product-xircle-page__news_categories {
  width: 100%;
  max-width: 1028px;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__news_categories {
    max-width: 678px;
  }
}
.product-xircle-page__news_title,
.product-xircle-page__content_title {
  margin: 80px auto 20px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__news_title,
  .product-xircle-page__content_title {
    max-width: 334px;
  }
}
.product-xircle-page__content_title {
  margin-bottom: 40px;
}
.product-xircle-page__content-links span {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product-xircle-page__contents {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1028px;
}
@media screen and (max-width: 1034px) {
  .product-xircle-page__contents {
    max-width: 678px;
  }
}
@media screen and (max-width: 767px) {
  .product-xircle-page__contents {
    flex-direction: column;
    align-items: center;
  }
}
.product-xircle-page__content-item {
  padding: 20px 20px 39px 20px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 125px;
  max-width: 334px;
  width: 100%;
}
.product-xircle-page__content-item h4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  margin: 0 0 5px 0;
}
.product-xircle-page__content-item a {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  align-items: center;
  color: #494d5b;
}
.product-xircle-page__content-item a:not(:last-of-type) {
  margin-bottom: 5px;
}
.slick-slider {
  height: 100%;
}
.slick-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 65px;
}
@media screen and (max-width: 1034px) {
  .slick-slide div {
    padding: 0 12px;
  }
}
.slick-dots {
  bottom: -20px;
}
@media screen and (max-width: 767px) {
  .slick-dots {
    bottom: -25px;
  }
}
.slick-dots li button:before {
  font-size: 12px;
}
.slick-dots li button {
  padding: 0;
}
.slick-dots li {
  margin: 0;
  width: 17px;
}
