/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.predefined-answers {
  margin-top: 21px;
}
.predefined-answers__title {
  border-top: solid 1px #d8d8d8;
  margin: 0px 27px 21px 22px;
  padding-top: 21px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}
.predefined-answers ul {
  margin: 0px 0px 0px 22px !important;
}
.predefined-answers ul li {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}
@media screen and (max-width: 375px) {
  .predefined-answers ul li {
    margin-bottom: 16px;
  }
}
.predefined-answers ul li:last-of-type {
  border-bottom: solid 1px #d8d8d8;
  padding-bottom: 23px;
  margin: 0 27px 0 0;
}
