/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.filter-wrapper {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: #f0f1f3;
}
.filter-wrapper .filter {
  width: 1025px;
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 1035px) {
  .filter-wrapper .filter {
    width: 690px;
  }
}
@media screen and (max-width: 768px) {
  .filter-wrapper .filter {
    width: auto;
  }
}
@media screen and (max-width: 460px) {
  .filter-wrapper .filter {
    flex-direction: column;
  }
}
.filter-wrapper .filter .item__input select,
.filter-wrapper .filter .item__input input {
  height: 56px;
  border: 0px;
  outline: 0px;
}
.filter-wrapper .filter .item__input--filters {
  position: relative;
  margin-right: 20px;
}
.filter-wrapper .filter .item__input--filters select {
  width: 226px;
  padding: 25px 8px 0 13px;
  border-bottom: 1px solid #aaacb2;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #494d5b;
  display: flex;
  align-items: flex-start;
  -webkit-appearance: none;
}
.filter-wrapper .filter .item__input--filters label {
  position: absolute;
  top: 10px;
  left: 14px;
  height: 13px;
  font-size: 11px;
  color: #aaacb2;
}
.filter-wrapper .filter .item__input--filters::after {
  content: '';
  position: absolute;
  top: 26px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #494d5b;
}
.filter-wrapper .filter .item__input--search input {
  box-sizing: border-box;
  width: 232px;
  border-radius: 28px;
  padding: 0 23px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}
@media screen and (max-width: 460px) {
  .filter-wrapper .filter .item__input--search input {
    margin-top: 20px;
  }
}
.filter-wrapper .filter .item__input--search input::placeholder {
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  color: #ccced3;
}
