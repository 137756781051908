/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.medication-card {
  border-radius: 10px;
  width: 335px;
  height: 126px;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  margin: 10px 5px 0;
}
@media screen and (max-width: 767px) {
  .medication-card {
    margin: 10px 0 0;
  }
}
@media screen and (max-width: 376px) {
  .medication-card {
    width: 100%;
  }
}
.medication-card .medication-card__title {
  display: block;
  color: #494d5b !important;
  margin: 20px 0 0 20px;
  max-width: 249px;
  font-weight: bold;
  font-size: 24px;
}
