/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.content-panel__disease-card {
  padding: 27px 21px 25px 22px;
}
.content-panel__disease-card .disease-card__title {
  max-width: 280px;
  margin: 0 0 12px 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494d5b;
}
.content-panel__disease-card .disease-card__link {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #E065A4;
}
