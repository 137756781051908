/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.code-input {
  height: 68px;
  border-radius: 10px;
  box-shadow: none;
}
.code-input.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 10px 0 0 10px;
}
.code-input.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 10px 10px 0;
}
