/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.instruction_item {
  margin-left: 35px;
}
@media screen and (max-width: 320px) {
  .instruction_item {
    margin-left: 25px;
  }
}
.instruction_item:first-child {
  margin-top: 24px;
}
.instruction_item:last-child {
  margin-top: 47px;
}
.instruction_item__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.instruction_item__header .instruction_item__title {
  margin: 0 0 0 14px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaacb2;
}
.instruction_item__header .instruction_item__image {
  width: 32px;
  height: 35px;
}
.instruction_item__content {
  margin-top: 9px;
  display: flex;
  flex-direction: column;
}
.instruction_item__content--multiple a:not(:first-child) {
  margin-top: 10px;
}
.instruction_item__content div {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494d5b;
}
.instruction_item__content div:last-child {
  margin-top: 2px;
}
.instruction_item__content .instruction_item__link--primary {
  color: #E065A4;
}
.instruction_item__content .instruction_item__link--secondary {
  color: #8DD2CF;
}
