/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.predefined-answers__item a {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ccced3;
  background-color: transparent;
  text-decoration: none;
  outline: none;
}
.predefined-answers__item a i {
  background-image: url('../../../../images/ic-check-circle-black.svg');
  background-repeat: no-repeat;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
