/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.drugs-catalogue-wrapper {
  height: 100vh;
}
.drugs-catalogue-wrapper .transition-group {
  background-color: #494d5b;
}
.drugs-catalogue-wrapper .transition-group,
.drugs-catalogue-wrapper .drugs-catalogue-page,
.drugs-catalogue-wrapper .drugs-catalogue-page__content {
  min-height: 100%;
}
.drugs-catalogue-page {
  display: flex;
  flex-direction: column;
}
.drugs-catalogue-page .drugs-catalogue-page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 26px;
  height: unset;
  min-height: fit-content;
}
.drugs-catalogue-page .drugs-catalogue-page__header h1 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.53px;
  text-align: left;
  color: #494d5b;
  margin: 0 0 30px 0;
}
.drugs-catalogue-page .drugs-catalogue-page__header .xircles-logo {
  margin: 88px 0 34px 0;
}
@media only screen and (max-width: 767px) {
  .drugs-catalogue-page .drugs-catalogue-page__header .xircles-logo {
    margin: 77px 0 52px 0;
  }
}
@media only screen and (max-width: 767px) {
  .drugs-catalogue-page .drugs-catalogue-page__header {
    padding: 0 20px 20px;
  }
  .drugs-catalogue-page .drugs-catalogue-page__header .drugs-catalogue-page__login h6 {
    display: none;
  }
}
@media only screen and (max-width: 680px) {
  .drugs-catalogue-page .drugs-catalogue-page__header .drugs-catalogue-page__welcome {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .drugs-catalogue-page .drugs-catalogue-page__header {
    height: 100%;
    padding-bottom: 28px;
  }
}
.drugs-catalogue-page .drugs-catalogue-page__content {
  display: flex;
  flex-direction: column;
  padding: 50px 0 117px 0;
  background-color: #f0f1f3;
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue-page__products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1035px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue-page__products {
    width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue-page__products {
    width: 335px;
  }
}
@media screen and (max-width: 375px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue-page__products {
    width: 100%;
  }
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__filter {
  width: 1026px;
  margin: 0 auto 12px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__filter .drugs-catalogue__filter-item {
  width: 16px;
  height: 26px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.53px;
  text-align: left;
  text-transform: uppercase;
  color: #E065A4;
  margin: 0 10px 10px 0;
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__filter .drugs-catalogue__filter-item--selected {
  color: white;
  background-color: #E065A4;
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__filter {
    width: 681px;
  }
}
@media screen and (max-width: 767px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__filter {
    width: 100% !important;
  }
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__second-pagination {
  width: 1026px;
  margin: 0 auto 4px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__second-pagination .drugs-catalogue__second-pagination-item {
  height: 26px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.53px;
  text-align: left;
  color: #494d5b;
  margin: 0 10px 10px 0;
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__second-pagination .selected {
  color: white;
  background-color: #494d5b;
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__second-pagination {
    width: 681px;
  }
}
@media screen and (max-width: 767px) {
  .drugs-catalogue-page .drugs-catalogue-page__content .drugs-catalogue__second-pagination {
    width: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .drugs-catalogue-page .menu-button {
    top: 10px;
    right: 10px;
  }
  .drugs-catalogue-page .drugs-catalogue-page__content {
    padding: 32px 28px 92px 28px;
  }
  .drugs-catalogue-page .auto-suggest {
    position: initial;
  }
}
