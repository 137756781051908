/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane .content-panel {
    max-width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .scrollPane .content-panel {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane .content-panel {
    max-width: 335px;
  }
  .questionPage .scrollPane .content-panel__large-block {
    max-width: 335px !important;
    max-height: unset !important;
  }
}
.questionPage .scrollPane .content-panel {
  margin: 6px auto 52px auto;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.questionPage .scrollPane .content-panel__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.questionPage .scrollPane .content-panel__row--start {
  justify-content: start;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane .content-panel__row--start {
    margin-left: 0;
  }
}
.questionPage .scrollPane .content-panel__large-block,
.questionPage .scrollPane .content-panel__medium-block,
.questionPage .scrollPane .content-panel__small-block {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
}
.questionPage .scrollPane .content-panel__large-block {
  max-height: 700px;
  max-width: 681px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.questionPage .scrollPane .content-panel__medium-block {
  width: 335px;
  height: 262px;
}
.questionPage .scrollPane .content-panel__small-block {
  width: 335px;
  min-height: 154px;
}
.questionPage .scrollPane .content-panel__right-block {
  padding-left: 9px;
}
@media only screen and (max-width: 1034px) {
  .questionPage .scrollPane .content-panel__right-block {
    padding-left: 0;
  }
}
.questionPage .scrollPane .content-panel .public-conversations {
  display: block !important;
  max-width: 335px !important;
  padding: 5px 0 0 0;
}
.questionPage .scrollPane .content-panel .public-conversations layer-conversation-item {
  margin: 5px 0 0 0;
}
.questionPage .scrollPane .content-panel .public-conversations layer-conversation-item .layer-list-item {
  margin-bottom: 0px;
}
.questionPage .scrollPane .content-panel__large-block div:nth-child(3),
.questionPage .scrollPane .content-panel__large-block div:last-child {
  border: none;
}
.questionPage.mobile-view .content-panel__large-block {
  max-height: unset;
}
