/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.navigation {
  width: 1026px;
  margin: 0 auto 20px auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
}
.navigation li {
  display: flex;
}
.navigation li a {
  color: #aaacb2;
  text-transform: capitalize;
}
.navigation li:not(:last-of-type):after {
  content: '»';
  display: block;
  margin: 0 10px;
  color: #aaacb2;
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .navigation {
    width: 681px;
  }
}
@media screen and (max-width: 767px) {
  .navigation {
    width: 100% !important;
    max-width: 334px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
