/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.brand-footer {
  min-height: 292px;
  padding: 50px 27px 0 29px;
  background-color: #494d5b;
}
.brand-footer .brand-footer__content {
  max-width: 940px;
  margin: 0 auto;
}
@media screen and (max-width: 1034px) {
  .brand-footer .brand-footer__content {
    max-width: 678px;
  }
}
@media screen and (max-width: 479px) {
  .brand-footer .brand-footer__content {
    max-width: none;
  }
}
.brand-footer .brand-footer__content .xircles-white-logo {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAABHCAMAAAAp6ruFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA/UExURUdwTPrm7////////////////////////////////////////////////+BlpOBlpN9kpO+0099jpf///+BlpFqn8cwAAAATdFJOUwAR73CiQMQg32CQgDBQjdI6X2Bz2v0xAAAExElEQVRo3u2b2XLrIAxAzQ42TjJ1/v9bb9LmtggkELazTCZ6dB3BEQItuMOwTk6H4/l8PIjhPUUczjc53Hcg6/9kfCDg8Xx+DKFf/kQ/ju9wTuT0foAi5Tsf3w8QLOB9l/A5gEcI+PXugIcP4GcPvhjgCQKKtwOEPnrXSP8kwHQJj+INAYfT8TF8TwP8zbbvXU48DfCCePr6Ot19lCcCPkY+gB/AD+AHcIu4OUwXCSZuAnRBXbSo2WBBy0FpvfATIvBfpI9/hxLxOrqayzijrPybvdTBrQKEarwOOeS4ADG5ggD+7MtnDn3VZqP7TK2xSyHadAMajaiBlhJwJJ8ZwPmlpOECKplZpoaHzK0F6DRHjZPgj1n3EapQQwegs8XS32xKzOsqUwegkpQWqcB7FSeFDqqHDkC49glg9EtFtOACjjU1YJ1oJ80m6ToAhV9wwCCXqnjHAhS2riZ9O5vLSDnoPHQAZvb1uE9gYgUDsMUHXzeEkwZiezAAczf0+FCtqZGAuq1Gke7830kFtYsYgAsO6DwDMJkaBThx1ERywUcM2+0ByOJbpGsAuvwHVmtt881t6VhhSm9Sww6ALMOnU/Ntz728Pd9cLmqIEchYcXVS6KB62AHQ5TaW43z5uTNTvrKmChjImAdDv6Vjhc4cFJzdvYDe3wCzk8EnFg74SY4Dpk9lrGQmho4VxpCL3QMox58MX8Ry58CQDo8fKSqAoZo8Wyrcm8LqZP7GBYQZE5EW4YSmAmirCWrKIZmpjx9WAXpH27bQCWZmVWUFHXG2Y8MYXnLgVgHmfG5pzExndARgaJRQijj7kUMOz/C5gFWzoMVdRkcAavJs+JGZHkY1k7oOQE0sEHE2fG8SJRj1YPpMmVICFSiKWIF7Ghcw1vaG39B0WjpE5g0OJJVCvIADWCLIFR0yBDD2AC61MxaNEGzAEqG6rfmApguwcL+xESHYgKpooDTcggs4bwPMY8W8FtDcCzBsA8wLNiveDNDutQfda7hovj5IwWZ2AnzOIVOzMtEKXg24U5hwsOhpSEXfxkzGVZNguVOg7/xEYGq3pzYAjs1UzYyRAWhbakiZmz2gTYBhqSSJ/7NVDxkxwLFxBF68mLhl880e0CZA0Tq7brsrZWyVS1KgVYkfzUCWY81O/1rArOAVlQ3nR0cDCtlYQo1fOMJayciKvVcCqvrZNSEmbbYskFQrJI0BR1W7U7Yhob1XAgpoNOsqWXCtJwMiYd5VG8DSGCJC+MJj9Q6A+Smdtg2zK1FfbRvaSsMB8HlqbFfWhmoHwKLc9N9dRRGVJYpQHNCQhnKamLRBbGLIWLEWkJ1H+kbrPreH19feRRgt1YyAEcKiu8LuAMi59QJbh7p8kSw1Ch/3d61IJ10PKFjXS2Pz+kx1+UFWYqm+i9EuQNYFoWdcgDLuqVoOSl+MbgFkEKZtPPqOvu3sEd+yIPEkbu+3ADYJmR8htNZQBuJNVeuyzTsAwu9nyrzXcT8jqRL6SBS5ttplk24HwOrUej4EqvhCYibohbLRpLG7AJLfYFlOPTiQ16aIe+ZLVNS2eSNZ7QJ4RSznZvs/xsMQrXqR/yyedeIdUq+dVpxSNXYywwuJMNcvYkMwbpueaIKaVJgRNf8AXFjBcgXpHboAAAAASUVORK5CYII=);
  max-width: 111px;
  max-height: 35px;
}
@media screen and (max-width: 1034px) {
  .brand-footer .brand-footer__content .xircles-white-logo {
    margin-bottom: 32px;
  }
}
.brand-footer .brand-footer__content .brand-footer__content-row {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1034px) {
  .brand-footer .brand-footer__content .brand-footer__content-row {
    flex-direction: column;
  }
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 334px;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:not(:last-of-type) {
  margin: 0 10px 0 0;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column h3 {
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column span {
  color: white;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link-wrapper span {
  font-size: 16px;
  line-height: 24px;
  color: white;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link-wrapper--company-link {
  margin: 25px 0;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link-wrapper--company-link span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link-wrapper--company-link span.city {
  display: initial;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link {
  color: white;
  line-height: 24px;
  font-size: 16px;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column .brand-footer__content-link--underlined {
  text-decoration: underline;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:last-child span {
  font-size: 16px;
  line-height: 24px;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:last-child span:not(.description) {
  font-weight: bold;
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:last-child .brand-footer__content-link {
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 1160px) {
  .brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:last-child {
    min-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column:last-child {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1034px) {
  .brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-column {
    max-width: 334px;
  }
}
.brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-description-columns {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1034px) {
  .brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-description-columns {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .brand-footer .brand-footer__content .brand-footer__content-row .brand-footer__content-description-columns {
    flex-direction: column;
  }
}
@media screen and (max-width: 1034px) {
  .brand-footer {
    min-height: 424px;
  }
}
@media screen and (max-width: 767px) {
  .brand-footer {
    min-height: 529px;
  }
  .brand-footer .brand-footer__content-column {
    min-width: unset !important;
  }
}
