.mdc-switch {
  display: inline-block;
  position: relative;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mdc-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}
.mdc-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #000;
  border-color: #000;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #fff;
  border-color: #fff;
}

.mdc-switch__native-control {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  position: absolute;
  top: 0;
  width: 68px;
  height: 48px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
}
[dir='rtl'] .mdc-switch__native-control,
.mdc-switch__native-control[dir='rtl'] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-switch__track {
  box-sizing: border-box;
  width: 32px;
  height: 14px;
  border: 1px solid;
  border-radius: 7px;
  opacity: 0.38;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb-underlay {
  /* @noflip */
  left: -18px;
  /* @noflip */
  right: initial;
  display: flex;
  position: absolute;
  top: -17px;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir='rtl'] .mdc-switch__thumb-underlay,
.mdc-switch__thumb-underlay[dir='rtl'] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: -18px;
}

.mdc-switch__thumb {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 10px solid;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
}

.mdc-switch--checked .mdc-switch__track {
  opacity: 0.54;
}

.mdc-switch--checked .mdc-switch__thumb-underlay {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
[dir='rtl'] .mdc-switch--checked .mdc-switch__thumb-underlay,
.mdc-switch--checked .mdc-switch__thumb-underlay[dir='rtl'] {
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

.mdc-switch--checked .mdc-switch__native-control {
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}
[dir='rtl'] .mdc-switch--checked .mdc-switch__native-control,
.mdc-switch--checked .mdc-switch__native-control[dir='rtl'] {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.mdc-switch--disabled {
  opacity: 0.38;
  pointer-events: none;
}
.mdc-switch--disabled .mdc-switch__thumb {
  border-width: 1px;
}
.mdc-switch--disabled .mdc-switch__native-control {
  cursor: default;
  pointer-events: none;
}

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0))
      scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
      scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
      scale(var(--mdc-ripple-fg-scale, 1));
  }
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0))
      scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
      scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
      scale(var(--mdc-ripple-fg-scale, 1));
  }
}

@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}

@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}

@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}

@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden;
}
.mdc-ripple-surface--test-edge-var-bug::before {
  border: var(--mdc-ripple-surface-test-edge-var);
}

.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::before,
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::after {
  background-color: #9e9e9e;
}

.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay:hover::before {
  opacity: 0.08;
}

.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before,
.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-switch:not(.mdc-switch--checked)
  .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-switch__thumb-underlay {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-switch__thumb-underlay::before,
.mdc-switch__thumb-underlay::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: '';
}
.mdc-switch__thumb-underlay::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before {
  -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-activation::after {
  -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-deactivation::after {
  -webkit-animation: mdc-ripple-fg-opacity-out 150ms;
  animation: mdc-ripple-fg-opacity-out 150ms;
  -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay::before,
.mdc-switch__thumb-underlay::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before,
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay::before,
.mdc-switch__thumb-underlay::after {
  background-color: #018786;
}
@supports not (-ms-ime-align: auto) {
  .mdc-switch__thumb-underlay::before,
  .mdc-switch__thumb-underlay::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786);
  }
}
.mdc-switch__thumb-underlay:hover::before {
  opacity: 0.04;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before,
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

/*# sourceMappingURL=switch.css.map*/
