/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.too-many-requests__message {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.35px;
  text-align: left;
  color: #494d5b;
  margin: 0;
}
.too-many-requests__robot-image {
  width: 44px;
  height: 40px;
  margin-top: 24px;
}
