/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane footer:not(.brand-footer) {
    max-width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .scrollPane footer:not(.brand-footer) {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane footer:not(.brand-footer) {
    max-width: 310px;
  }
  .questionPage .scrollPane footer:not(.brand-footer) ul {
    flex-direction: column;
    align-items: flex-start;
  }
  .questionPage .scrollPane footer:not(.brand-footer) ul li {
    margin-bottom: 10px;
  }
}
.questionPage .scrollPane footer:not(.brand-footer) {
  border-top: 1px solid #ccced3;
  margin: 0 auto 0 auto;
  padding: 8px 0 43px 0;
  font-size: 13px;
}
@media screen and (max-width: 320px) {
  .questionPage .scrollPane footer:not(.brand-footer) {
    padding: 0 10px 0 10px;
  }
}
.questionPage .scrollPane footer:not(.brand-footer) ul {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
}
.questionPage .scrollPane footer:not(.brand-footer) ul li {
  display: inline-block;
  color: #aaacb2;
  padding-right: 20px;
  cursor: pointer;
}
.questionPage .scrollPane footer:not(.brand-footer) ul li.name {
  font-weight: bold;
}
