/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.tag {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 2px;
  color: #ffffff;
  background-color: #ccced3;
  min-height: 27px;
  border-radius: 13.5px;
  font-size: 15px;
  font-weight: normal;
}
.tag:first-child {
  margin-right: 10px;
  margin-left: 10px;
}
.tag:not(:first-child) {
  margin-right: 10px;
}
