/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.content-panel__instruction-card {
  width: 325px;
  height: 262px;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .content-panel__instruction-card {
    width: 335px;
  }
}
@media screen and (max-width: 320px) {
  .content-panel__instruction-card {
    width: 100%;
    margin-left: 15px;
  }
}
