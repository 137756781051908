/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.content-editable {
  position: relative;
  background-color: #ffffff;
  max-width: 668px;
}
.content-editable .send {
  position: absolute;
  right: -13px;
  bottom: 0;
  z-index: 12;
  padding: 0 0 0 20px;
}
@media only screen and (max-width: 768px) {
  .content-editable .send {
    bottom: -12px;
  }
  .content-editable .send.mdc-icon-button {
    width: 72px;
    height: 72px;
  }
}
.content-editable .send::before,
.content-editable .send::after {
  display: none;
}
.content-editable .send .send-button-spinner {
  top: 1px;
  left: 12px;
  width: unset;
  height: unset;
}
@media only screen and (max-width: 768px) {
  .content-editable .send .send-button-spinner {
    top: 12px;
    left: 24px;
  }
}
.content-editable__placeholder {
  width: 240px;
}
.content-editable_OFF__highlight {
  margin-left: -3px;
  margin-right: -3px;
  background-color: rgba(0, 155, 119, 0.2);
  border: 2px solid transparent;
  border-left-width: 3px;
  border-right-width: 3px;
  border-radius: 5px;
}
.content-editable__input {
  padding: 4px 22px 3px 6px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  color: #494d5b;
  position: relative;
  outline: none;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (max-width: 504px) {
  .content-editable__input {
    padding-right: 26px;
  }
}
.content-editable__placeholder {
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 24px;
  font-weight: bold;
  color: #f0f1f3;
}
.ie .content-editable .send-button-spinner {
  top: 5px;
  left: 10px;
}
.question.question-product .content-editable__placeholder {
  width: unset;
}
.corrections-wrapper {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.corrections-wrapper .correction-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #ccced3;
  min-height: 27px;
  min-width: 111px;
  border-radius: 13.5px;
  font-size: 15px;
}
.corrections-wrapper .correction-item i {
  font-size: 22px;
  padding-bottom: 2px;
}
.corrections-wrapper .correction-item:active {
  background-color: #494d5b;
}
.corrections-wrapper .correction-item:not(:last-child) {
  margin-right: 10px;
}
.mobile-view .corrections-wrapper .correction-item:not(:last-child) {
  margin-right: 5px;
}
