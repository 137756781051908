/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.question .bigButtonBarWrapper {
  position: relative;
}
.question .bigButtonBar {
  border-top: 1px solid #f0f1f3;
  font-size: 13px;
  color: #aaacb2;
  position: relative;
  visibility: hidden;
  bottom: 0;
  box-sizing: border-box;
  transition: opacity 200ms ease-in-out, visibility 0s 200ms;
  opacity: 0;
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .question .bigButtonBar {
    flex-direction: column;
  }
}
.question .bigButtonBar__shortcut-container {
  display: flex;
  flex-wrap: wrap;
}
.question .bigButtonBar__message {
  font-weight: bold;
}
.question .bigButtonBar__message p {
  margin: 0;
  color: #E065A4;
  min-width: max-content;
  line-height: 26px;
}
.question .bigButtonBar .short-cut {
  display: inline-block;
  width: 120px;
  cursor: pointer;
  border: none;
}
@media only screen and (max-width: 768px) {
  .question .bigButtonBar {
    width: 100%;
    border-top-width: 1px;
  }
}
.questionFixed .question .bigButtonBarVisible {
  opacity: 0;
  margin-bottom: 31px;
}
@media only screen and (max-width: 1035px) {
  .questionFixed .question .bigButtonBarVisible {
    margin-bottom: 38px;
  }
}
@media only screen and (max-width: 768px) {
  .questionFixed .question .bigButtonBarVisible {
    width: calc(100% - 32px);
    margin-bottom: 61px;
  }
}
@media only screen and (max-width: 376px) {
  .questionFixed .question .bigButtonBarVisible {
    margin-bottom: 23px;
  }
}
.questionFixed .question--first-time .bigButtonBarVisible {
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: 28px;
  }
}
@media only screen and (max-width: 434px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 365px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: -15px;
  }
}
@media only screen and (max-width: 375px) {
  .question .bigButtonBar .short-cut {
    width: 90px;
  }
}
