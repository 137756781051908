/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.select-dialog .mdc-dialog__content {
  overflow-y: scroll !important;
}
.select-dialog .mdc-dialog__surface {
  width: 678px;
  max-width: unset !important;
}
@media only screen and (max-width: 767px) {
  .select-dialog .mdc-dialog__surface {
    width: unset;
    max-width: 560px !important;
  }
}
@media only screen and (max-width: 590px) {
  .select-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px) !important;
  }
}
.select-dialog > .mdc-dialog__container {
  height: fit-content !important;
}
