/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.xircles-info-dialog .route-section {
  position: static !important;
}
.xircles-info-dialog--custom .mdc-dialog__container {
  height: 227px !important;
}
.xircles-info-dialog--custom .route-section {
  padding: 5px 20px 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.xircles-info-dialog__privacy-notice .mdc-dialog__container {
  height: 450px !important;
}
.xircles-info-dialog__privacy-notice .mdc-dialog__surface {
  width: 678px;
  max-width: 678px !important;
  height: 450px;
}
.xircles-info-dialog__privacy-notice .route-section {
  padding-top: 0px !important;
}
.xircles-info-dialog__privacy-notice .route-section .privacy-notice__message {
  margin: 0 0 10px 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.29px;
  text-align: left;
}
.xircles-info-dialog__privacy-notice .route-section .privacy-notice__message span {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .xircles-info-dialog__privacy-notice .mdc-dialog__container,
  .xircles-info-dialog__privacy-notice .mdc-dialog__surface {
    height: 100% !important;
  }
  .xircles-info-dialog__privacy-notice .mdc-dialog__container {
    padding: 25px;
  }
  .xircles-info-dialog__privacy-notice .mdc-dialog__surface {
    width: 100% !important;
  }
  .xircles-info-dialog__privacy-notice .mdc-dialog__content {
    overflow-y: scroll !important;
  }
}
.xircles-info-dialog__terms-of-use .mdc-dialog__container {
  height: 243px !important;
}
.xircles-info-dialog__terms-of-use .mdc-dialog__surface {
  width: 678px;
  max-width: 678px !important;
  height: 243px;
}
.xircles-info-dialog__terms-of-use .route-section {
  padding-top: 0px !important;
}
.xircles-info-dialog__terms-of-use .route-section .terms-of-use__message {
  margin: 0 0 10px 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.29px;
  text-align: left;
}
.xircles-info-dialog__terms-of-use .route-section .terms-of-use__message span {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .xircles-info-dialog__terms-of-use .mdc-dialog__container,
  .xircles-info-dialog__terms-of-use .mdc-dialog__surface {
    height: fit-content !important;
  }
  .xircles-info-dialog__terms-of-use .mdc-dialog__container {
    padding: 25px;
  }
  .xircles-info-dialog__terms-of-use .mdc-dialog__surface {
    width: 100% !important;
  }
}
