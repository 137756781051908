/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-xircle-page__news_category-news {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__news_category-news {
    flex-direction: column;
    align-items: center;
  }
  .product-xircle-page__news_category-news .product-xircle-page__news_category-item:not(:nth-child(-n + 2)) {
    display: none;
  }
}
.product-xircle-page__news_category-news--expanded {
  overflow: none;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__news_category-news--expanded .product-xircle-page__news_category-item:not(:nth-child(-n + 2)) {
    display: flex;
  }
}
.product-xircle-page__news_category {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__news_category {
    align-items: center;
  }
}
.product-xircle-page__news_category:not(:last-of-type) {
  margin-bottom: 40px;
}
.product-xircle-page__news_subtitle {
  margin: 0 auto 20px;
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
}
.product-xircle-page__news_category-item {
  box-sizing: border-box;
  width: 334px;
  min-width: 334px;
  min-height: 287px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
}
.product-xircle-page__news_category-item h4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  margin: 0 auto 5px 0;
}
.product-xircle-page__news_category-item p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
}
.product-xircle-page__news_category-item a {
  margin-top: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product-xircle-page__show-all {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .product-xircle-page__show-all {
    position: static;
    margin-top: 10px;
  }
}
