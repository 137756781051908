/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.questionPage .panel-header .title h1 {
  font-size: 22px;
  color: #494d5b;
  margin: 20px 0 41px 0;
}
@media only screen and (max-width: 1034px) {
  .questionPage .panel-header .title h1 {
    margin-top: 21px;
    margin-bottom: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .questionPage .panel-header .title h1 {
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 22px;
  }
}
.questionPage .panel-header .title__operator h1 {
  font-size: 24px;
  margin-bottom: 34px;
}
@media only screen and (max-width: 1034px) {
  .questionPage .panel-header .title__operator h1 {
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .questionPage .panel-header .title__operator h1 {
    margin-bottom: 18px;
  }
}
.questionPage .panel-header .deleted-requests__title h1 {
  margin-bottom: 25px;
}
@media only screen and (max-width: 1034px) {
  .questionPage .panel-header .deleted-requests__title h1 {
    margin-bottom: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .questionPage .panel-header .deleted-requests__title h1 {
    margin-bottom: 9px;
  }
}
.questionPage .panel-header_disabled {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  pointer-events: none;
}
.questionPage .left-panel.fixed .panel-header {
  xtransition: none;
}
.questionPage .left-panel.open .panel-header {
  transition: top 30ms ease-out, margin-top 200ms 30ms ease-out;
  margin-top: -410px;
  xtop: -100px;
}
.questionPage .left-panel .panel-header {
  width: 100%;
  position: relative;
  transition: top 30ms 200ms ease-out, margin-top 200ms ease-out;
  top: 0;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
}
.questionPage .left-panel .panel-header .grey-top {
  width: 100vw;
  height: 35px;
  background-color: #494d5b;
}
.questionPage .left-panel .panel-header h6 {
  position: absolute;
  color: #494d5b;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
  cursor: pointer;
}
.questionPage .left-panel .panel-header .login {
  max-width: 150px;
  color: #E065A4;
  right: 111px;
  top: 25px;
  position: fixed;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.questionPage .left-panel .panel-header .login__operator {
  position: absolute;
  top: 50px;
  right: 219px;
}
.questionPage .left-panel .panel-header .menu {
  position: fixed;
  right: 54px;
  top: 25px;
}
.questionPage .left-panel .panel-header .menu__operator {
  position: absolute;
  top: 50px;
  right: 162px;
}
.questionPage .left-panel .panel-header .menu-button__operator {
  position: absolute;
  top: 35px;
  right: 121px;
}
@media only screen and (max-width: 1035px) {
  .questionPage .left-panel .panel-header .menu-button__operator {
    right: 15px;
  }
}
.questionPage .left-panel .panel-header .widget__back-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 9px 23px 0px;
  max-width: 700px;
  box-sizing: border-box;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .questionPage .left-panel .panel-header .widget__back-button-wrapper {
    padding-left: 0px;
    box-sizing: initial;
  }
}
.questionPage .left-panel .panel-header .widget__back-button {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
}
.questionPage .left-panel .panel-header .widget__back-button i {
  color: #E065A4;
}
.questionPage .left-panel .panel-header .widget__back-button--hidden {
  display: none;
}
.questionPage .left-panel .panel-header .title__operator {
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 767px) {
  .questionPage .left-panel .panel-header .login {
    display: none;
  }
  .questionPage .left-panel .panel-header .menu-button {
    position: absolute;
    top: 0;
  }
  .questionPage .left-panel .panel-header .menu {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .left-panel .panel-header .menu {
    display: none;
  }
  .questionPage .left-panel .panel-header .login {
    position: absolute;
    right: 64px;
  }
  .questionPage .left-panel .panel-header .menu-button {
    position: absolute;
  }
  .questionPage .left-panel .panel-header .title {
    margin-top: 30px;
  }
  .questionPage .left-panel .panel-header .title h1 {
    margin-bottom: 27px;
  }
}
.productPage .panel-header .title h1 {
  margin: 20px 0 41px 0;
}
.productPage .panel-header .title .product-title {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1025px;
  margin: 0 auto;
}
@media screen and (max-width: 1034px) {
  .productPage .panel-header .title .product-title {
    max-width: 720px;
    padding: 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title .product-title {
    max-width: 700px;
    box-sizing: border-box;
  }
}
.productPage .panel-header .title .product-title__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 0 187px;
}
@media only screen and (max-width: 1200px) {
  .productPage .panel-header .title .product-title__content {
    max-width: 685px;
    margin: 0 0 0 187px;
  }
}
@media only screen and (max-width: 1034px) {
  .productPage .panel-header .title .product-title__content {
    margin: 0 0 0 32px;
  }
}
@media only screen and (max-width: 890px) {
  .productPage .panel-header .title .product-title__content {
    max-width: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title .product-title__content {
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
    margin: 0 0 0 8px;
  }
}
.productPage .panel-header .title .product-title__name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.productPage .panel-header .title .product-title__specialist-button {
  margin: 2px 10px 2px 0px;
  max-height: 34px;
}
.productPage .panel-header .title .product-title__specialist-button a {
  padding: 0;
  margin: 0;
  color: unset;
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title .product-title__specialist-button {
    max-height: 24px;
    padding: 0 8px !important;
  }
  .productPage .panel-header .title .product-title__specialist-button a {
    font-size: 13px !important;
  }
}
.productPage .panel-header .title .product-title__open-leaflet {
  max-height: 34px;
  margin: 2px 0;
}
.productPage .panel-header .title .product-title__open-leaflet a {
  padding: 0;
  margin: 0;
  color: unset;
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title .product-title__open-leaflet {
    max-height: 24px;
    padding: 0 8px !important;
  }
  .productPage .panel-header .title .product-title__open-leaflet a {
    font-size: 13px !important;
  }
}
.productPage .panel-header .title .product-title__controls_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
}
.productPage .panel-header .title .product-title__back-button {
  color: #E065A4;
  padding: 0 !important;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 5px;
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title .product-title__back-button {
    top: -23px;
    left: 20px;
  }
  .productPage .panel-header .title .product-title__back-button i.material-icons {
    font-size: 18px;
  }
}
.productPage .panel-header .title .product-title__back-button--fixed {
  position: fixed;
  top: 13px;
}
.productPage .panel-header .title h1 {
  margin: 0 0 3px 0;
}
.productPage .panel-header .title h1 + span {
  margin: 0 25px 3px 5px;
  font-size: 22px;
}
@media only screen and (max-width: 376px) {
  .productPage .panel-header .title h1 + span {
    margin: 0 0 3px 5px;
  }
}
@media only screen and (max-width: 767px) {
  .productPage .panel-header .title {
    margin: 35px 0 1px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1034px) {
  .productPage .panel-header .title {
    margin: 22px 0 0 0 !important;
  }
  .productPage .panel-header .title h1 {
    margin: 0 0 3px 0 !important;
  }
}
.productPage .left-panel .panel-header .login {
  top: 30px;
  z-index: 10;
  position: absolute;
}
.productPage .left-panel .panel-header .menu {
  position: absolute;
  z-index: 10;
  top: 30px;
}
