.doccheck-doc-image {
  position: absolute;
  left: 33px;
  top: 15px;
}
.closeBtn::before,
.backBtn::before,
.closeBtn::after,
.backBtn::after {
  display: none;
}
