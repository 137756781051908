/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.select-list {
  padding: 5px 20px 20px 20px;
  margin: 0;
  list-style-type: none;
}
.select-list__option {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.35px;
  text-align: left;
  color: #494d5b;
  margin-bottom: 10px;
  padding-left: 10px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.select-list__option:last-child {
  margin-bottom: 0px;
}
.select-list__option::before {
  content: '\BB';
  color: #E065A4;
  vertical-align: text-bottom;
  position: absolute;
  left: 0px;
  top: -1px;
}
@media only screen and (max-width: 580px) {
  .select-list__option {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: initial;
  }
}
.select-list__option--selected {
  font-weight: bold;
}
